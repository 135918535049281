<template>
    <v-app>
        <v-app-bar justify="center" color="matchpal">
            <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
            <v-toolbar-title>Administradores</v-toolbar-title>
        </v-app-bar>

        <v-container>
            <v-row dense justify="center">
                <v-col class="mb-12 pb-12" cols="12" sm="8" lg="7">
                    <v-list three-line max-width="1000px">
                        <template v-for="(item, index) in administradores">
                            <v-list-item :key="item.id" @click="cliqueAdm(index)">
                                <!--<v-list-item-avatar class="mr-3">
                                    <v-img v-if="usuarios[index-1].path_usuario_img" :src="usuarios[index-1].path_usuario_img"></v-img>
                                </v-list-item-avatar>-->

                                <v-list-item-content align="left">
                                    <v-list-item-title> {{ usuarios[index].nome }} </v-list-item-title>
                                    <v-list-item-subtitle> {{ usuarios[index].celular }} </v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ !item.isComerciante ? 'Administrador' : 'Proprietário' }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-btn v-if="!item.isComerciante && myStatus=='proprietario'" class="mr-3" icon
                                    @click="trocarAdm(usuarios[index].nome, item.id, index)">
                                    <v-icon size="30">emoji_events</v-icon>
                                </v-btn>
                                <v-btn v-if="!item.isComerciante" class="mr-3" icon @click="dialog = true">
                                    <v-icon size="30">lock_open</v-icon>
                                </v-btn>
                                <WhatsLink v-if="!item.isComerciante"
                                    class="mr-3"
                                    :phone="unformatPhone(convite.phone)"
                                    :text="convite.message"
                                    icon="share"
                                />
                                <v-btn v-if="!item.isComerciante" icon
                                    @click="excluir(usuarios[index].nome, item.usuario_id, index)">
                                    <v-icon size="30">clear</v-icon>
                                </v-btn>
                            </v-list-item>
                            <v-divider v-if="!item.header" :key="index" />
                        </template>
                    </v-list>
                </v-col>
            </v-row>
            <v-btn class="mr-3 mb-2" fab large dark bottom right
                fixed @click="addAdm">
                <v-icon>person_add</v-icon>
            </v-btn>
        </v-container>

        <v-dialog id="modal-permissoes" v-model="dialog" scrollable max-width="500">
            <v-card v-if="index >= 0" class="pa-5">
                <v-card-title class="headline">Permissões</v-card-title>
                <v-card-text style="max-height: 500px;">
                    <template v-if="index > 0">
                        <!-- Se não for comerciante -->
                        <v-switch
                            v-for="(item,i) in permissoesLabel"
                            :key="i"
                            v-model="permissoesAdms[index][item.id]"
                            :label="item.label"
                            :disabled="!administradores[index].isComerciante ? false : true"
                        />
                    </template>
                    <template v-else>
                        <v-switch
                            v-for="(item,i) in permissoesLabel"
                            :key="i"
                            :label="item.label"
                            disabled
                            input-value="true"
                        />
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="blue darken-1" text @click="dialog = false">Cancelar</v-btn>
                    <v-btn dark class="ev-color-fixo" @click="salvarPermissoes">Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <Snackbar
            :snackbar-flag="snackbar.activeFlag"
            :permanent="snackbar.permanentFlag"
            :error="snackbar.errorFlag"
            :color="snackbar.color"
            :hide-close-buttom="snackbar.isConfirmMode">
            <template v-slot:message>{{ snackbar.text }}</template>
            <template v-slot:actions>
                <WhatsLink
                    v-if="snackbar.phone && !snackbar.isConfirmMode"
                    class="v-btn v-btn--flat v-btn--text theme--light v-size--default indigo--text"
                    :phone="snackbar.phone"
                    :text="snackbar.whatsappMsg"
                    icon="after"
                >Avisar</WhatsLink>
                <v-btn
                    v-if="snackbar.isConfirmMode"
                    color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0"
                >Sim</v-btn>
                <v-btn
                    v-if="snackbar.isConfirmMode"
                    color="error"
                    @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0"
                >Não</v-btn>
            </template>
        </Snackbar>

        <v-dialog id="modal-add-adm" v-model="dialogFab" persistent max-width="600px">
            <v-card>
                <v-container>
                    <v-card-title>
                        <span class="headline">Administrador</span>
                    </v-card-title>
                    <v-card-text>
                            <v-row>
                                <v-col class="icon-btn-on-input" cols="12" sm="6">
                                    <v-text-field :id="`${prefix}-name`"
                                        v-model="novoAdm.nome" label="Nome*"
                                        type="text" name="name" :autocomplete="(contactsAPISuport||!isMobile)?'off':'name'"
                                        required />
                                    <v-icon v-if="contactsAPISuport"
                                        class="icon-btn-on-input-right"
                                        @click="abreContatos">far fa-address-book</v-icon>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field :id="`${prefix}-tel`"
                                        v-model="novoAdm.celular"
                                        v-maska="'(##) # ####-####'"
                                        label="Celular*"
                                        type="tel" name="phone" :autocomplete="(contactsAPISuport||!isMobile)?'off':'tel'"
                                        required />
                                </v-col>

                                <v-col cols="12">
                                    <v-expansion-panels accordion hover>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header>Permissões*</v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-switch
                                                    v-for="(item,i) in permissoesLabel"
                                                    :key="i"
                                                    v-model="novoAdm.permissoesAdm[item.id]"
                                                    :label="item.label"
                                                />
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>
                                    </v-expansion-panels>
                                </v-col>
                            </v-row>
                        <small>*obrigatório</small>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="blue darken-1" text @click="dialogFab = false">Fechar</v-btn>
                        <v-btn color="blue darken-1" text @click="cadastrar">Cadastrar</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
import { maska } from 'maska'
import Utils from '@components/Utils/Utils.vue'
import detectMobileBrowser from '@plugins/detectmobilebrowser.js'


export default {
    name: 'Administradores',
    components: {
        Snackbar: () => import('@components/Snackbar.vue'),
        WhatsLink: () => import('@components/WhatsappLink.vue')
    },
    directives: { maska },
    mixins: [ Utils ],
    data: () => ({
        dialog: false,
        dialogFab: false,
        index: -1,
        // Header
        estabelecimento: {},
        //Administradores
        administradores: [],
        usuarios: [],
        novoAdm: {
            nome: '',
            celular: '',
            permissoesAdm: {
                alterar_config_quadras: 1,
                bloquear_agenda: 1,
                confirmar_24h_antes: 1,
                desmarcar_horario_avulso: 1,
                desmarcar_horario_fixo: 1,
                editar_fotos_estabelecimento: 1,
                id: 0,
                liberar_horario_fixo_semana: 1,
                marcar_horario_avulso: 1,
                marcar_horario_fixo: 1,
                super_adm: 0,
                versao: 0,
                visualizar_hist_financeiro: 1
            }
        },

        //Permissões
        permissoesLabel: [
            { label: 'Marcar horário avulso', id: 'marcar_horario_avulso' },
            {
                label: 'Desmarcar horário avulso',
                id: 'desmarcar_horario_avulso'
            },
            { label: 'Marcar horário fixo', id: 'marcar_horario_fixo' },
            { label: 'Desmarcar horário fixo', id: 'desmarcar_horario_fixo' },
            {
                label: 'Liberar horário fixo',
                id: 'liberar_horario_fixo_semana'
            },
            { label: 'Bloquear agenda', id: 'bloquear_agenda' },
            {
                label: 'Visualizar histórico financeiro',
                id: 'visualizar_hist_financeiro'
            },
            {
                label: 'Editar fotos do estabelecimento',
                id: 'editar_fotos_estabelecimento'
            },
            {
                label: 'Alterar configurações das quadras',
                id: 'alterar_config_quadras'
            }
        ],
        permissoesAdms: [],
        myStatus: '',
        convite: {
            share: false,
            phone: '',
            message: ''
        },
        prefix: 0,
        isMobile: detectMobileBrowser(),
    }),
    watch: {
        flagContactSelected: function() {
            this.novoAdm.nome = this.contactInfo.name
            this.novoAdm.celular = this.contactInfo.tel
        },
    },
    mounted() {
        this.fetchEstabelecimento()
        this.buscaAdms()
    },
    methods: {
        fetchEstabelecimento: function(date) { //TODO: Verificar este método
            this.$http.post('/apiweb/getEstabelecimento').then(response => {
                this.estabelecimento = response.data.info
                this.convite.message = `Você foi convidado para ser administrador do estabelecimento ${this.estabelecimento.nome}. Para ter acesso as funções de gestão, acesse o link https://app.zumer.com.br`
            })
        },
        cliqueAdm(index) {
            this.index = index
            this.convite.phone = this.usuarios[index].celular
        },
        buscaAdms() {
            this.$http.post('/administradores/getAllAdms').then(response => {
                if (response.data.success === true) {
                    this.administradores = response.data.administradores
                    this.usuarios = response.data.usuarios.map(u => ({nome: u.nome, celular: this.formatPhone(u.celular)}))
                    this.permissoesAdms = response.data.permissoesAdms
                    this.myStatus = response.data.myStatus
                } else {
                    console.log('Erro ao atualizar informações dos administradores!')
                    location.reload()
                }
            })
        },
        excluir(nome, usuario_id) {
            this.confirm(
                `Tem certeza que deseja remover ${nome} como administrador?`
            ).then(confirm => {
                if (!confirm) return (this.dialog = false)
                this.dialog = false
                this.$http({
                    method: 'post',
                    url: '/administradores/remove',
                    data: {
                        usuario_id: usuario_id,
                        versao_administrador: 0,
                        versao_admPendente: 0
                    }
                }).then(response => {
                    if(response.data.mensagem === 'Permissao negada'){
                        return this.notify('Você não possui permissão para efetuar a operação!', true, false)
                    }
                    this.buscaAdms()
                    this.notify(`${nome} foi removido com sucesso!`, false)
                    this.index = 0
                })
            })
        },
        trocarAdm(nome, adm_id) {
            this.confirm(
                `Tem certeza que deseja tornar ${nome} proprietário do estabelecimento?`
            ).then(confirm => {
                if (!confirm) return (this.dialog = false)
                this.dialog = false
                this.$http({
                    method: 'post',
                    url: '/apiweb/trocaProprietario',
                    data: {
                        adm_id,
                        estabelecimento_id: this.estabelecimento.id
                    }
                }).then(response => {
                    if(response.data.mensagem === 'Permissao negada'){
                        return this.notify('Você não possui permissão para efetuar a operação!', true, false)
                    }
                    if(response.data.mensagem === 'id_incorreto'){
                        return this.notify('Contate o suporte para realizar essa operação.', true, false)
                    }
                    this.buscaAdms()
                    this.notify(`Operação realizada. ${nome} é o proprietário de ${this.estabelecimento.nome}!`, false)
                    this.index = 0
                })
            })
        },
        cadastrar() {
            if(this.novoAdm.nome && this.novoAdm.celular){
                this.dialogFab = false
                this.$http({
                    method: 'post',
                    url: '/administradores',
                    data: {
                        nome: this.novoAdm.nome,
                        celular: this.unformatPhone(this.novoAdm.celular),
                        permissoesAdm: JSON.stringify(this.novoAdm.permissoesAdm),
                        versao_administrador: 0,
                        versao_admPendente: 0
                    }
                }).then(response => {
                    this.buscaAdms()
                    if (response.data.mensagem === 'ok') {
                        this.notify(
                            `${this.novoAdm.nome} cadastrado com sucesso!`,
                            false,
                            true,
                            this.unformatPhone(this.novoAdm.celular),
                            this.convite.message
                        )
                    } else if(response.data.mensagem === 'comerciante existente') {
                        this.notify('Este comerciante já esta cadastrado!')
                    } else if(response.data.mensagem === 'Permissao negada') {
                        this.notify('Você não possui permissões para efetuar a operação!')
                    } else {
                        this.notify('Houve um erro ao cadastrar. Por favor contate nosso suporte!', true, false)
                    }
                })
            }else{
                this.notify('Preencha todos os campos.', true, false)
            }

        },
        salvarPermissoes() {
            this.dialog = false
            this.$http({
                method: 'post',
                url: '/permissoesadm',
                data: {
                    permissoesAdm: JSON.stringify(
                        this.permissoesAdms[this.index]
                    )
                }
            }).then(response => {
                this.buscaAdms()
                if (response.data.mensagem === 'ok') {
                    this.notify('Permissões alteradas com sucesso!', false)
                }
            })
        },
        addAdm(event) {
            this.prefix = Math.trunc(Math.random() * 100000000)
            this.dialogFab = true
        },
    }
}
</script>
