<template>
    <v-app>
        <v-card>
            <v-app-bar justify="center" color="matchpal">
                <v-icon style="margin-right:10px;" size="30" @click="$router.go(-1)">keyboard_return</v-icon>
                <v-toolbar-title>Pix</v-toolbar-title>
            </v-app-bar>
            <v-container>
                <v-row justify="center" dense>
                    <v-col class="col-xs-12 col-sm-12 col-md-9 col-12">
                        <v-card outlined tile>
                            <v-card-title>Integração MercadoPago</v-card-title>
                            <v-divider />
                            <v-card-text v-if="!tokenInfo" class="text-body-1">
                                Para habilitar recebimentos por Pix siga os passos abaixo:
                                <ol class="list">
                                    <li class="mb-4">
                                        Crie sua conta no MercadoPago (<a href="http://www.mercadopago.com.br" target="blank">www.mercadopago.com.br</a>).
                                    </li>
                                    <li class="mb-4">
                                        Cadastre uma chave pix na sua conta do MercadoPago (<a href="https://youtu.be/60tApKYVnkA" target="blank">vídeo</a>).<br>
                                        <span class="text-body-2">Pode ser uma chave aleatória, pois seus clientes pagarão através do QR code gerado pelo sistema.</span>
                                    </li>
                                    <li class="mb-4">
                                        Obtenha as credenciais para integração (<a href="https://www.youtube.com/watch?v=RIe5FbQ8S7w" target="blank">vídeo</a>). Após ver o vídeo, clique nesse <a href="https://www.mercadopago.com.br/mlb/account/credentials" target="blank">link</a>, acesse as "Credenciais de produção" e copie os itens "Public Key" e "Access Token".
                                    </li>
                                    <li class="mb-4">
                                        Adicione as credenciais de integração ao seu estabelecimento colando nos campos abaixo e clicando em Enviar.
                                    </li>
                                </ol>

                                <v-text-field v-model="publicKey"
                                    label="Public Key"
                                    name="publicKey" autocomplete="off" />

                                <v-text-field v-model="accessToken"
                                    label="Access Token"
                                    name="accessToken" autocomplete="off" />

                                <div class="text-right">
                                    <v-btn color="matchpalAlert"
                                        @click="setTokenMP()">Enviar</v-btn>
                                </div>
                            </v-card-text>
                            <v-card-text v-if="tokenInfo" class="text-body-1">
                                Credencial adicionada:<br>
                                <div class="text-body-2 ml-4 mb-4">
                                    <b>Data:</b> {{ tokenInfo.date }}<br>
                                    <b>Cadastrada por:</b> {{ tokenInfo.admin_name }}<br>
                                    <b>Status:</b> <span :class="{ 'token-working-false': !tokenInfo.working }"> {{ (tokenInfo.working)? "Ativa" : "Inválida (exclua a credencial e repita o cadastramento)" }}</span><br>
                                    <b>Pix:</b> {{ (tokenInfo.pix_habilitado)? "Habilitado" : "Desabilitado " }}
                                    <span v-if="!tokenInfo.pix_habilitado"> (chaves pix cadastradas fora do horário comercial serão validadas pelo Banco Central apenas no próximo dia útil)</span>

                                </div>
                                <div class="text-right">
                                    <v-btn color="matchpalAlert"
                                        @click="deleteTokenMP()">Excluir</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>

                        <v-card outlined tile class="mt-4">
                            <v-card-title>Reservas web</v-card-title>
                            <v-divider />
                            <v-card-text class="text-body-1">
                                <b>Link:</b> zumer.com.br/{{ url }}<br>
                                <v-switch
                                    v-model="reservaSemPgto"
                                    :label="`Reserva web sem pagamento`"
                                    :disabled="!url"
                                    @change="setReservaSemPgto()"
                                    />
                                <div class="text-body-2 ml-12 mt-n4">
                                    O cliente envia uma solicitação que deverá ser aceita pelo estabelecimento para efetivar a reserva.
                                </div>

                                <v-switch
                                    v-model="reservaPix"
                                    :label="`Reserva web com pix`"
                                    :disabled="!url || !tokenInfo || !tokenInfo.pix_habilitado"
                                    @change="setReservaPix()"
                                    />
                                <div class="text-body-2 ml-12 mt-n4">
                                    A reserva é inserida no sistema no momento da solicitação. Se após 30 minutos não houver o pagamento, a reserva é cancelada.
                                </div>

                                <v-switch
                                    v-if="false"
                                    v-model="reservaCartao"
                                    :label="`Reserva web com cartão de crédito`"
                                    :disabled="!url || !tokenInfo"
                                    @change="setReservaCartao()"
                                    />
                                <div v-if="false" class="text-body-2 ml-12 mt-n4">
                                    A reserva só é inserida no sistema após a aprovação do pagamento pelo MercadoPago.
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <Snackbar :snackbar-flag="snackbar.activeFlag" :permanent="snackbar.permanentFlag"
            :error="snackbar.errorFlag" :color="snackbar.color" :hide-close-buttom="snackbar.isConfirmMode">
            <template v-slot:message>{{ snackbar.text }}</template>
            <template v-slot:actions>
                <v-btn v-if="snackbar.isConfirmMode" color="error"
                    @click.prevent="snackbar.confirm = false; snackbar.activeFlag = 0">Não</v-btn>
                <v-btn v-if="snackbar.isConfirmMode" color="matchpal"
                    @click.prevent="snackbar.confirm = true; snackbar.activeFlag = 0">Sim</v-btn>
            </template>
        </Snackbar>

        <Load :load="load" />
    </v-app>
</template>

<script>
import Utils from "@components/Utils/Utils.vue"
export default {
    name: "Pix",
    components: {
        Load: () => import('@components/Load.vue'),
        Snackbar: () => import('@components/Snackbar.vue'),
    },
    mixins: [ Utils ],
    data: () => ({
        load: true,
        tokenInfo: [],
        url: '',
        reservaSemPgto: false,
        reservaPix: false,
        reservaCartao: false,
        accessToken:"",
        publicKey:""
    }),
    beforeMount() {
        this.$http({
            method: "post",
            url: "/apiweb/pix/getTokenInfo"
        }).then(response => {
            if (response.data.success === true) {
                this.tokenInfo = response.data.tokenInfo
                this.url = response.data.url
                this.reservaSemPgto = response.data.reservaSemPgto
                this.reservaPix = response.data.reservaPix,
                this.reservaCartao = response.data.reservaCartao
            } else {
                // TODO: sem_estabelecimento
            }

            this.load = false
        })
    },
    methods: {
        setReservaPix: function(){
            this.$http({
                method: "post",
                url: "/apiweb/pix/setReservaPix",
                data:  {
                    reservaPix: this.reservaPix,
                }
            }).then(response => {
                if (response.data.success !== true)
                    this.reservaPix = !this.reservaPix
            }).catch(() => {
                this.reservaPix = !this.reservaPix
            })
        },
        setReservaCartao: function(){
            this.$http({
                method: "post",
                url: "/apiweb/pix/setReservaCartao"
            }).then(response => {
                if (response.data.success !== true)
                    this.reservaCartao = !this.reservaCartao
            }).catch(() => {
                this.reservaCartao = !this.reservaCartao
            })
        },
        setReservaSemPgto: function(){
            this.$http({
                method: "post",
                url: "/apiweb/pix/setReservaSemPgto"
            }).then(response => {
                if (response.data.success !== true)
                    this.reservaSemPgto = !this.reservaSemPgto
            }).catch(() => {
                this.reservaSemPgto = !this.reservaSemPgto
            })
        },
        setTokenMP: function () {
            this.load = true
            this.$http({
                method: "post",
                url: "/apiweb/pix/setTokenMP",
                data: {
                    accessToken: this.accessToken,
                    publicKey: this.publicKey
                }
            }).then(response => {
                if (response.data.success === true) {
                    this.tokenInfo = response.data.tokenInfo
                    this.url = response.data.url
                    this.reservaSemPgto = response.data.reservaSemPgto
                    this.reservaPix = response.data.reservaPix,
                    this.reservaCartao = response.data.reservaCartao

                    if (this.tokenInfo.pix_habilitado) {
                        this.reservaPix = true
                        this.setReservaPix()
                    }
                } else {
                // TODO: sem_estabelecimento
                }
                this.load = false
            }).catch(()=>{
                this.load = false
            })

        },
        deleteTokenMP: function () {
            this.load = true
            this.confirm("Atenção! O recebimento por pix será desabilitado. Tem certeza que deseja excluir suas credenciais?")
                .then(confirm => {
                    if(confirm === true){
                        this.$http({
                            method: "post",
                            url: "/apiweb/pix/deleteTokenMP"
                        }).then(response => {
                            if (response.data.success === true) {
                                this.tokenInfo = response.data.tokenInfo
                                this.url = response.data.url
                                this.reservaSemPgto = response.data.reservaSemPgto
                                this.reservaPix = response.data.reservaPix
                                this.reservaCartao = response.data.reservaCartao
                                this.accessToken = ""
                                this.publicKey = ""
                            } else {
                                this.notify(response.data.message)
                            }
                            this.load = false
                        }).catch(()=>{
                            this.load = false
                        })
                    }else{
                        this.load = false
                    }
                })
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'

    p
        color: #fff
        font-size: 1.5em
        font-weight: bold

    .token-working-false
        color: $orange
</style>