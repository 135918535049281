<template>
    <v-app id="filters">
        <v-navigation-drawer v-model="openViewModel" fixed temporary right width="330" @transitionend="controledepainel = []">
            <v-toolbar color="matchpal" style="position: sticky; top: 0; z-index: 10; margin-bottom: 5px;">
                <v-icon class="mr-4" size="32" @click="openViewModel = false">close</v-icon>
                <v-toolbar-title>Filtro</v-toolbar-title>
            </v-toolbar>

            <v-container>
                <!-- <p class="subtitle-1">Filtrar por:</p> -->
                <!-- <v-radio-group v-model="filterBy" class="mb-5" :mandatory="false">
                    <v-radio label="Quadra" value="quadra" @change="cleanFilter" />
                    <v-radio label="Esporte" value="esporte" @change="cleanFilter" />
                </v-radio-group> -->

                <v-row align="center">

                    <v-expansion-panels
                    v-model="controledepainel"
                    :accordion="true"
                    :flat="true"
                    :hover="true"
                    :multiple="false"
                    >
                    <v-expansion-panel>
                        <v-expansion-panel-header label="Quadra" value="quadra"
                        @click="cleanFilterEsportes()">Quadras</v-expansion-panel-header>
                        <v-expansion-panel-content>

                                <v-row v-for="i in quadras.length" :key="i">
                                    <span style="display: flex; align-items: center">
                                        <v-switch :id="`quadra${i}`" v-model="quadrasSelecionadas"
                                        inset
                                        append-icon="fas fa-store-alt"
                                        :value="quadras[i-1].id" :items="quadras"
                                         />
                                        <label :for="`quadra${i}`" class="Nome" style="padding-left: 10px; cursor: pointer">
                                            {{ quadras[i-1].nome }}
                                        </label>
                                    </span>
                                </v-row>

                        </v-expansion-panel-content>
                    </v-expansion-panel>


                <!-- Itens com _ na frente estão comentados -->
                <!-- <v-autocomplete v-show="filterBy === 'quadra'" ref="quadra" v-model="quadrasSelecionadas"
                    :items="quadras" label="Quadras" item-text="nome" item-value="id"
                    no-data-text="Nenhuma quadra disponível" chips multiple dense
                    _clearable _clear-icon="fas fa-trash-alt"
                    :menu-props="{'maxHeight': '55%'}" @blur="closeQuadraFilter">
                    <template v-slot:selection="data">
                        <v-chip v-bind="data.attrs" :input-value="data.selected" close
                            @click="data.select" @click:close="removeQuadra(data.item)">
                            <v-avatar left>
                                <v-icon size="17">fas fa-store-alt</v-icon>
                            </v-avatar>
                            {{ data.item.nome }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template>
                        <v-list-item-avatar>
                            <v-icon size="20">fas fa-store-alt</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ data.item.nome }}</v-list-item-title>
                        </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete> -->

                    <v-expansion-panel>
                        <v-expansion-panel-header v-model="esportesSelecionados"
                        :items="esportes" label="Esportes" :menu-props="{'maxHeight': '55%'}"
                        @click="cleanFilterQuadras()">Esportes</v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <v-row v-for="i in esportes.length" :key="i">
                                    <span style="display: flex; align-items: center">
                                        <v-switch :id="`esporte${i}`" v-model="esportesSelecionados" inset
                                        :append-icon="mapSportsicon[esportes[i-1].value]"
                                        :v-model="esportesSelecionados"
                                        :value="esportes[i-1].value" />
                                        <label :for="`esporte${i}`" class="Nome" style="padding-left: 10px; cursor: pointer">
                                            {{ esportes[i-1].name }}
                                        </label>
                                    </span>
                                 </v-row>
                            </v-expansion-panel-content>

                    </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>

                <!-- Itens com _ na frente estão comentados -->
                <!-- <v-autocomplete v-show="filterBy === 'esporte'" ref="esporte" v-model="esportesSelecionados"
                    :items="esportes" label="Esportes" item-text="name" item-value="value"
                    no-data-text="Nenhum esporte disponível" chips multiple dense
                    _clearable _clear-icon="fas fa-trash-alt"
                    :menu-props="{'maxHeight': '55%'}" @blur="closeEsporteFilter">
                    <template v-slot:selection="data">
                        <v-chip v-bind="data.attrs" :input-value="data.selected" close
                            @click="data.select" @click:close="removeEsporte(data.item)">
                            <v-avatar left>
                                <v-icon size="20">{{ mapSportsicon[data.item.value] }}</v-icon>
                            </v-avatar>
                            {{ data.item.name }}
                        </v-chip>
                    </template>
                    <template v-slot:item="data">
                        <template>
                        <v-list-item-avatar>
                            <v-icon>{{ mapSportsicon[data.item.value] }}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                        </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete> -->

                <div class="row d-flex justify-center mt-5">
                    <v-btn class="mr-5" color="error" @click="cleanFilter">Limpar</v-btn>
                    <v-btn dark @click="dispatchFilter">Aplicar</v-btn>
                </div>
            </v-container>
        </v-navigation-drawer>

        <Snackbar :snackbar-flag="snackbar" :permanent="permanetSnackbar" :error="errorSnackbar">
            <template v-slot:message>{{ textSnackbar }}</template>
            <template v-if="phoneSnackbar" v-slot:actions>
                <WhatsLink class="v-btn v-btn--flat v-btn--text theme--light v-size--default indigo--text"
                    :phone="phoneSnackbar" icon="after">
                    Avisar
                </WhatsLink>
            </template>
        </Snackbar>
    </v-app>
</template>

<script>
export default {
    name: 'Filtro',
    components: {
        WhatsLink: () => import('@components/WhatsappLink.vue'),
        Snackbar: () => import('@components/Snackbar.vue'),
    },
    props: {
        filterFlag: {
            type: Number,
            default: 0,
        },
        quadras: {
            type: Array,
            default: new Array(),
        },
    },
    data: () => ({
        controledepainel: [],
        openViewModel: false,
        filterBy: 'quadra',
        snackbar: 0,
        textSnackbar: '',
        phoneSnackbar: '',
        errorSnackbar: false,
        permanetSnackbar: true,
        mapSportsicon: {
            0:  'sports',                   // Genérico
            1:  'sports_basketball',        // Basquete
            3:  'far fa-futbol',            // Futebol de campo
            4:  'sports_soccer',            // Futebol de areia
            5:  'fas fa-futbol',            // Futebol society
            6:  'sports_handball',          // Handebol
            7:  'sports_tennis',            // Padel
            8:  'sports_soccer',            // Futebol de salão
            9:	'sports_tennis',            // Tênis
            10:	'sports_volleyball',        // Vôlei
            11: 'fas fa-bowling-ball',      // Boliche
            12: 'sports',                   // Paintball
            13:	'sports_tennis',            // Beach Tennis
            14:	'sports_soccer',            // Futevôlei
            15:	'fas fa-volleyball-ball',   // Vôlei de praia
        },
        esportesSelecionados: [],
        quadrasSelecionadas: [],
    }),
    computed: {
        esportes: function() {
            return this.quadras.reduce((espArray, q) => espArray.concat(
                q.esportes.map(e => ({name: e.tipo, value: e.id}))
            ), []).filter((item, key, a) => // Filter para remover duplicados
                a.findIndex(x => x.value === item.value) === key
            )
        },
    },
    watch: {
        filterFlag: function() {
            this.openViewModel = true
        },
        quadras: function() {
            this.dispatchFilter()
        },
    },
    mounted() {
        window.addEventListener('popstate', this.popstate)
    },
    destroyed() {
        window.removeEventListener('popstate', this.popstate, false)
    },
    methods: {
        popstate(){
            this.openViewModel = false
        },
        cleanFilter(event) {
            this.esportesSelecionados = this.quadrasSelecionadas = []
        },
        cleanFilterEsportes(event) {
            this.esportesSelecionados = []
            this.filterBy = 'quadra'
        },
        cleanFilterQuadras(event) {
            this.quadrasSelecionadas = []
            this.filterBy = 'esporte'
        },
        closeQuadraFilter(event) {
            this.$refs.quadra.isMenuActive = false
        },
        closeEsporteFilter(event) {
            this.$refs.esporte.isMenuActive = false
        },
        dispatchFilter(event) {
            this.openViewModel = false
            let quadrasToShow = null
            if (this.filterBy === 'quadra')
                quadrasToShow = this.quadras.filter(q => this.quadrasSelecionadas.includes(q.id)).map(q => q.id)
            else if (this.filterBy === 'esporte')
                quadrasToShow = this.quadras.filter(q => q.esportes.some( e => this.esportesSelecionados.includes(e.id) ))
                    .map(q => q.id)
            this.$emit('change', quadrasToShow)
        },
    }
}
</script>

<style lang="sass" scoped>
    @import '@assets/css/global-vars.sass'


    .v-navigation-drawer--fixed
        max-width: 100% !important
        max-width: 100vw !important

    .v-autocomplete
        .v-avatar.v-avatar--left
            justify-content: normal

    .Nome
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: 70%
        font-size: 1.1em
        color: #353535

    @media (max-width: 2000px)
        .v-navigation-drawer--fixed
            width: 30% !important
            width: 30vw !important

    @media (max-width: 1200px)
        .v-navigation-drawer--fixed
            width: 50% !important
            width: 50vw !important

    @media (max-width: 768px)
        .v-navigation-drawer--fixed
            width: 70% !important
            width: 70vw !important

    @media (max-width: 600px)
        .v-navigation-drawer--fixed
            width: 80% !important
            width: 80vw !important

    @media (max-width: 400px)
        .v-navigation-drawer--fixed
            width: 100% !important
            width: 100vw !important
</style>